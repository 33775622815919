import { FC, ReactNode } from "react";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";
import { Outlet } from "react-router-dom";



const LayoutIndex = () => {
    return ( <>
    <p>lorem</p>
    <HeaderIndex/>
    <Outlet/>
    <FooterIndex/>
    
    </> );
}
 
export default LayoutIndex;