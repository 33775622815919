import LazyLoadImage from "apps/Common/LazyLoadImage";
import Logo from "assets/Images/Logo.svg";

const HeaderIndex = () => {
  return (
    <>
      <div className="grid grid-cols-12 !bg-[#f1f5fc] rounded-xl p-4 mx-1">
        <div className="col-span-12 lg:col-span-6">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6">
              <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-4">
                  <LazyLoadImage
                    wrapperWidth="100px"
                    wrapperHeight="100px"
                    style={{ objectFit: "contain" }}
                    src={Logo}
                    alt="Sari ilanlar logosu"
                  />
                </div>

                <div className="col-span-12 lg:col-span-8 flex gap-4 items-center">
                  <span>Arac</span>
                  <span>Gayri mankul</span>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 flex items-center">
             
                <input
                  className="!h-[30px] !w-full rounded-lg pl-2 !border-black" // Notice the `pl-8` to account for the icon
                  type="text"
                  placeholder="Ara..."
                />
            
              
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6">test</div>
      </div>
    </>
  );
};

export default HeaderIndex;
