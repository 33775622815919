
import trTR from "antd/lib/locale/tr_TR";
import { Button, ConfigProvider} from 'antd';
// import 'antd/dist/reset.css'; 
import React from 'react';
import { RouteList } from 'routes/RouteList';
// import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs';
// import { renderToString } from 'react-dom/server';

const App: React.FC = () => (
  <>

   <ConfigProvider
    theme={{
   
    }}
    locale={trTR}
  > 
    <Button>omid</Button> 
       <RouteList />
  </ConfigProvider>
  </>
);

export default App;
