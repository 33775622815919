import React, { useEffect, useRef, useState } from 'react';

interface LazyImageProps {
    src: string;
    alt: string;
    style:any,
    wrapperWidth:string;
    wrapperHeight:string
}

const LazyLoadImage: React.FC<LazyImageProps> = ({ src, alt,style,wrapperWidth,wrapperHeight }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const imgRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); 
                }
            },
            { threshold: 0.1 } 
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, []);

    return (
     
        <div ref={imgRef} style={{width:"40px", height:"40px"}} >
            {isVisible ? <img width={"100%"} style={style} height={"100%"} src={src} alt={alt} /> : <div style={{ height: '200px', background: '#f0f0f0' }} />}
        </div>
    );
};

export default LazyLoadImage