

import Init from "apps/Common/Init";
import LandingIndex from "apps/Landing/LandingIndex";
import HeaderIndex from "layout/Header/HeaderIndex";
import LayoutIndex from "layout/LayoutIndex";
import { Navigate, Route, Routes } from "react-router-dom";

export const RouteList = () => {
  return (
    <Routes>
      <Route element={<LayoutIndex/>}>
     <Route path="/" element={<LandingIndex/>}  />
      
       
            {/* <Route element={<DashboardLayout />}>
            <Route path="/" element={<Navigate to="/car/list" replace />} />
             <Route path="/dashboard" element={<></>} />
             {companyRouteList}
             {buildingRouteList}
             {stationRouteList}
             {carRouteList}
             {productRouteList}
             {contractRouteList}
             {userRouteList}
          
            </Route> */}
      </Route>
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};
